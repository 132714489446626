import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Confirmation() {
  return (
    <div className="max-w-3xl mx-auto py-8 text-center">
      <p className="text-2xl font-bold mb-4">Welcome to the community!</p>
      <p>Your registration has been completed.</p>
      <p>You are on our waiting list, we will be in touch with you soon.</p>
      <AnchorLink to="/" title="ooofer" className="btn-primary mt-6">
        Back to home page
      </AnchorLink>
    </div>
  );
}
